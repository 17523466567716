import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Campaigns = Loadable(lazy(() => import('views/tweetsy/Campaigns')));
const Teleprompter = Loadable(lazy(() => import('views/tweetsy/teleprompter')));
const AddNewCampaign = Loadable(lazy(() => import('views/tweetsy/addNewCampaign')));
const Campaign = Loadable(lazy(() => import('views/tweetsy/Campaign')));
const Subscription = Loadable(lazy(() => import('views/tweetsy/subscription')));
const Settings = Loadable(lazy(() => import('views/tweetsy/Settings')));
const Expired = Loadable(lazy(() => import('views/tweetsy/expired')));
const Unauthenticated = Loadable(lazy(() => import('views/tweetsy/unauthenticated')));
const NotFound = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/unauthenticated',
            element: <Unauthenticated />
        },
        {
            path: '/expired',
            element: <Expired />
        },

        {
            path: '/campaigns',
            element: <Campaigns />
        },
        {
            path: '/teleprompter',
            element: <Teleprompter />
        },
        {
            path: '/campaign/new',
            element: <AddNewCampaign />
        },
        {
            path: '/campaign/:id',
            element: <Campaign />
        },
        // {
        //     path: '/templates',
        //     element: <Templates />
        // },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/subscription',
            element: <Subscription />
        },
        {
            path: '/*',
            element: <NotFound />
        }
    ]
};

export default MainRoutes;
