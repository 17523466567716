import Routes from 'routes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

import 'react-toastify/dist/ReactToastify.css';
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { useEffect, useState } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
    const [reloadTimer, setReloadTimer] = useState('');

    // Create a timer that fires every 50 minutes.
    const startReloadTimer = () => {
        setReloadTimer(
            setTimeout(() => {
                window.location.reload();
            }, 3600000)
        ); // 55 minutes in milliseconds
    };

    // Start the reload timer when the component mounts.
    useEffect(() => {
        startReloadTimer();

        return () => {
            clearTimeout(reloadTimer);
        };
    }, []);

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
