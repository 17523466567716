// third-party

// assets
import {
    IconUserSearch,
    IconVaccineBottle,
    IconTestPipe,
    IconBook2,
    IconTemplate,
    Icon3dCubeSphere,
    IconSettings,
    IconInbox,
    IconDashboard,
    IconDatabaseExport,
    IconAward,
    IconReportAnalytics,
    IconDeviceAnalytics,
    IconList,
    IconAlien,
    IconHeart,
    IconMessage2,
    IconBrandChrome,
    IconScreenShare
} from '@tabler/icons';

// constant
const icons = {
    IconUserSearch,
    IconTestPipe,
    IconBook2,
    IconTemplate,
    Icon3dCubeSphere,
    IconSettings,
    IconInbox,
    IconDashboard,
    IconDatabaseExport,
    IconAward,
    IconReportAnalytics,
    IconDeviceAnalytics,
    IconAlien,
    IconHeart,
    IconList,
    IconVaccineBottle,
    IconMessage2,
    IconBrandChrome,
    IconScreenShare
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    // title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'campaigns',
            title: 'Dashboard',
            type: 'item',
            url: '/campaigns',
            icon: icons.IconTemplate,
            breadcrumbs: false
        },
        {
            id: 'teleprompter',
            title: 'Teleprompter',
            type: 'item',
            url: '/teleprompter',
            icon: icons.IconList,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
